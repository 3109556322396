import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading:{
      show:false,
      titulo:'',
      color:'primary',
    },
  },
  mutations: {
    showLoading(state,payload){
      state.loading.titulo=payload.titulo;
      state.loading.color=payload.color;
      state.loading.show=true;
    },
    hideLoading(state){
      state.loading.show=false;
    }
  },
  actions: {
  },
  modules: {
  }
})
