import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: () => import('@/views/Main.vue') },
  { path: '/about', name: 'About', component: () => import( '@/views/About.vue') },
  { path: '/contact', name: 'Contac', component: () => import( '@/views/Contact.vue') },
  { path: '/features', name: 'Features', component: () => import( '@/views/Features.vue') },
  { path: '/404', name: 'Notfound', component: () => import( '@/views/NotFound.vue') },
  { path: '*', redirect: '/404'}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    else {
      return { x: 0, y: 0 }
    }
  
  }
})

export default router
