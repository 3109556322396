<template>
    <v-app>
        
        <v-dialog v-model="loading.estado" hide-overlay persistent width="300">
            <v-card :color="loading.color" dark>
                <v-card-text>
                {{ loading.mensaje }}
                <v-progress-linear indeterminate color="white" class="mb-0">
                </v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-app-bar color="red darken-1" app fixed >
             <v-avatar>
                    <svg width="210mm" height="297mm" viewBox="0 0 210 297" version="1.1" id="svg5" inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)" sodipodi:docname="logo.svg" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
                        <sodipodi:namedview id="namedview7" pagecolor="#ffffff" bordercolor="#666666" borderopacity="1.0" inkscape:pageshadow="2" inkscape:pageopacity="0.0" inkscape:pagecheckerboard="0" inkscape:document-units="mm" showgrid="false" inkscape:zoom="0.51906082" inkscape:cx="817.82324" inkscape:cy="654.06593" inkscape:window-width="1920" inkscape:window-height="1009" inkscape:window-x="1592" inkscape:window-y="-8" inkscape:window-maximized="1" inkscape:current-layer="g36"/>
                        <defs id="defs2"/>
                        <g inkscape:label="Capa 1" inkscape:groupmode="layer" id="layer1">
                            <g style="fill:none" id="g36" transform="matrix(2.2396472,0,0,2.2911755,-14.888723,19.511717)">
                                <path d="M 13.473874,8.3807666 V 98.464416 L 64.387702,83.166566 48.270786,53.588435 64.38627,23.677419 Z" fill="#95a6c8" id="path21" sodipodi:nodetypes="cccccc" style="stroke-width:1.64457"/>
                                <path d="M 44.207534,57.216627 95.26457,8.3804266 H 65.505434 L 19.983942,50.223699 Z" fill="#000000" id="path23" sodipodi:nodetypes="ccccc" style="stroke-width:1.64457"/>
                                <path d="M 19.983942,50.223699 65.505434,98.463736 H 95.26457 L 44.207534,49.599659 Z" fill="#000000" id="path25" sodipodi:nodetypes="ccccc" style="stroke-width:1.64457"/>
                            </g>
                        </g>
                    </svg>        
            </v-avatar>
            <v-toolbar-title class="ml-n3">readores</v-toolbar-title>
            <div class="d-none d-sm-flex">
                <v-divider class="mx-6"></v-divider>
                <v-btn  rounded color="red darken-1 text text--accent-4" @click="$vuetify.goTo('#header')" >
                    <v-icon left>mdi-home</v-icon>INICIO 
                </v-btn>
                    <v-divider class="mx-1"></v-divider>
                <v-btn rounded color="red darken-1 text text--accent-4" @click="$vuetify.goTo('#about-me')" >
                    <v-icon left>mdi-information</v-icon>ACERCA 
                </v-btn>
                    <v-divider class="mx-1"></v-divider>
                <v-btn  rounded color="red darken-1 text text--accent-4" @click="$vuetify.goTo('#features')" >
                    <v-icon left>mdi-file-document-edit</v-icon>SERVICIOS
                </v-btn>
                    <v-divider class="mx-1"></v-divider>
                <v-btn  rounded color="red darken-1 text text--accent-4" @click="$vuetify.goTo('#contact-me')" >
                    <v-icon left>mdi-email-edit</v-icon>CONTACTO
                </v-btn>
            </div>
            <v-spacer  ></v-spacer>
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer"  right temporary  app>
            <v-list nav dense rounded>
                <v-list-item-group v-model="group"  active-class="red darken-1 text text--accent-4">
                    <!--
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item>
                    -->
                    <v-list-item @click="$vuetify.goTo('#header')" :exact="true">
                        <!--
                        <v-list-item-avatar>
                            <svg width="88" height="76" viewBox="20 0 88 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26.1789 10.6054L26.1789 63.6056L58.1753 54.6052L58.1744 19.6051L26.1789 10.6054Z" fill="#95A6C8"/>
                                <path d="M55.7754 39.3377L87.8618 10.6052H69.1599L31.8618 42.6052L55.7754 39.3377Z" fill="black"/>
                                <path d="M31.8618 31.6052L69.1599 63.6052H87.8618L55.7754 34.8563L31.8618 31.6052Z" fill="black"/>
                            </svg>
                        </v-list-item-avatar>
                        -->
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item>                    
                    <v-list-item @click="$vuetify.goTo('#about-me')" :exact="true">
                        <v-list-item-icon>
                            <v-icon>mdi-information</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Acerca</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$vuetify.goTo('#features')" :exact="true">
                        <v-list-item-icon>
                            <v-icon>mdi-file-document-edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Servicios</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$vuetify.goTo('#contact-me')" :exact="true">
                        <v-list-item-icon>
                            <v-icon>mdi-email-edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Contacto</v-list-item-title>
                    </v-list-item>
                    <!--
                    <v-list-item @click="$vuetify.goTo('#about-me')" :exact="true">
                        <v-list-item-icon>
                            <v-icon>mdi-information</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>About</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name:'Notfound'}" :exact="true">
                        <v-list-item-icon>
                            <v-icon>mdi-whatsapp</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Whatsapp</v-list-item-title>
                    </v-list-item> 
                    -->                   
                </v-list-item-group>                
                <!--
                <v-divider></v-divider>
                <v-list-item link>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">
                            Sandra Adams
                        </v-list-item-title>
                        <v-list-item-subtitle>sandra_a88@gmail.com</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                -->
            </v-list>
        </v-navigation-drawer>        
        <v-main>    
            <v-slide-x-transition mode="out-in">    
                <router-view></router-view>
            </v-slide-x-transition>
        </v-main>    
        <v-footer app  elevation="6" color="transparent" paddless> 
             <v-container fill-height >
                <v-row no-gutters>
                  <v-col cols="4" align-self="start"  >
                      <span :class="[$vuetify.breakpoint.smAndDown ? 'text-sm-body-1' : 'text-sm-body-2']">
                            <p class="white--text">
                                &copy; {{ new Date().getFullYear() }} - Kreadores - Todos los derechos reservados
                            </p>                          
                      </span>                    
                  </v-col>
                  <v-col  cols="8" align-self-end >
                    <v-btn small elevation="4" color="grey" @click.native="privacy = !privacy">Privacidad</v-btn>            
                  </v-col>
                </v-row>
              </v-container>            
            <v-spacer></v-spacer>                
            <v-snackbar v-model="privacy" color="rgba(0, 0, 0, .6)"  elevation-10>                
                <span class="white--text text-caption">
                    <p> Política de privacidad de 
                       <a color="error" href="https://www.kreadores.com.mx">www.kreadores.com.mx</a> 
                        Esta Página recoge algunos Datos Personales de sus Usuarios.
                        Datos Personales tratados para las siguientes finalidades y utilizando los siguientes servicios:
                        <ul>
                            <li>
                                Contactar con el Usuario
                            </li>
                            <li>
                                Formulario de contacto

                            </li>
                        </ul>
                        Datos Personales: apellido(s); dirección de correo electrónico; nombre; telefono;
                        <br>    
                        Última revisión: 31 enero 2022 <v-btn outlined small color="" @click="dialog = !dialog" class="black-text"> Muestra la política de privacidad completa</v-btn>
                    </p>
                </span>
                <v-btn block text outlined color="white" @click.native="privacy = false">Aceptar</v-btn>
                <!--
                <v-btn  @click="privacy=!privacy" small elevation="4" color="black" class="align-self-end">
                     <v-icon color="white">mdi-close-box-multiple </v-icon>
                </v-btn>                
                -->
            </v-snackbar>                       
        </v-footer>
        <v-dialog v-model="dialog" scrollable  persistent :overlay="true" max-width="500px" transition="dialog-transition">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                Politica de privacidad
                </v-card-title>
                <v-card-text>
                <politica></politica>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  color="primary" text @click="dialog = false" >
                    Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>
import { mapState} from 'vuex';
import politica from './views/Privacy.vue';
    export default {
        components: {
            politica
        },
        data() {
            return {
                drawer: false,   
                group:null,
                privacy:true,
                dialog:false,
                politica:""
            }
        },
        computed: {
            ...mapState(['loading'])
        },
        watch: {
            group () {
                this.drawer = false
            },
        },
    }
</script>